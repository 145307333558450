import React, { Component } from 'react';
import Asesor from './Asesor'
import Layout from './Layout'
import Navbar from './Navbar'

import { API, graphqlOperation, Storage } from 'aws-amplify'
import { listAsesors } from '../graphql/queries'

class Asesores extends Component {

   state = {
      asesores: [],
      nextToken: "",
      next: false
   }

   componentDidMount = async () => {
      this.nextAsesores()
   }

   nextAsesores = async () => {
      const nextToken = this.state.nextToken === "" ? null : this.state.nextToken
      const result = await API.graphql(graphqlOperation(listAsesors, { nextToken }))
      this.procesar(result.data.listAsesors)
   }

   procesar = async (lista) => {
      lista.items.forEach(item => {
         this.obtenerImagen(item)
      })
      await this.setState({ nextToken: lista.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   obtenerImagen = async (objeto) => {
      Storage.get(objeto.urlImagen)
      .then(result => {
         const input = {
            id: objeto.id,
            nombre: objeto.nombre,
            telefono: objeto.telefono,
            correo: objeto.correo,
            descripcion: objeto.descripcion,
            urlImagen: objeto.urlImagen,
            url: result
         }
         this.setState({ asesores: [...this.state.asesores, input] })
      })
      .catch(err => console.log(err));
   }

   render(){
      const { asesores } = this.state
      return(
         <Layout>
            <Navbar />
            <div className="">
               <div className="">
                  <div className="flex flex-wrap justify-around">
                     <Asesor data={asesores}></Asesor>
                  </div>
                  { this.state.next && (
                     <div className=''>
                        <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextAsesores}>Mostrar más</button>
                     </div>
                  ) }
               </div>
            </div>
         </Layout>
      );
   }
}

export default Asesores