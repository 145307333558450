import React, { Component } from 'react';
import Eliminar from './Eliminar'
import Modificar from './Modificar'
import Imagenes from './Imagenes/Imagenes'

class InmueblesTabla extends Component {

   handleRecargar = async () => {
      this.props.handleRecargar()
   }

   render(){
      const inmuebles = this.props.data

      return inmuebles.sort((a,b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0)).map((inmueble) => {
         return(
            <tr key={inmueble.id}>
               <td className="border px-1 py-2">{inmueble.folio}</td>
               <td className="border px-1 py-2">{inmueble.titulo}</td>
               <td className="border px-1 py-2">{inmueble.precio}</td>
               <td className="border px-1 py-2">{inmueble.ubicacion}</td>
               <td className="border px-1 py-2">{inmueble.tipo}</td>
               <td className="border px-1 py-2">{inmueble.recamaras}</td>
               <td className="border px-1 py-2">{inmueble.banios}</td>
               <td className="border px-1 py-2">{inmueble.estacionamientos}</td>
               <td className="border px-1 py-2">{inmueble.superficie}</td>
               <td className="border px-1 py-2 text-center">
                  <div className='flex justify-around'>
                     <Imagenes data={inmueble} handleRecargar={this.handleRecargar} />
                     <Modificar data={inmueble} handleRecargar={this.handleRecargar} />
                     <Eliminar data={inmueble.id} handleRecargar={this.handleRecargar} />
                  </div>
               </td>
            </tr>
         )
      })
   }
}

export default InmueblesTabla