/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_cognito_identity_pool_id": "us-east-1:962bf65e-b7cd-46fa-8dcd-3a2930686e7b",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_dVLUvKykp",
    "aws_user_pools_web_client_id": "6jl2nos7ratd0gspbjnip9do12",
    "oauth": {},
    "aws_user_files_s3_bucket": "administracioned8cda3f1168453aa605774594b05ab8223507-dev",
    "aws_user_files_s3_bucket_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://2uh5rkb4vrh27i6pcmoxn2kfmm.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-sl6oxvn2zzbp5iqve2ttbcfi6e"
};


export default awsmobile;
