import React,{ Component } from 'react'
import { Link } from 'react-router-dom'
import './Estylo.css'
import Imagen from './Imagen'

class Tarjeta extends Component {
   render(){
      var inmuebles = this.props.data
      const ordenar = this.props.orden
      let orden = ""
      
      if(ordenar === "fecha"){
         inmuebles = inmuebles.sort((a,b) => (a.createdAt > b.createdAt ? -1 : a.createdAt < b.createdAt ? 1 : 0))
      }
      else{
         inmuebles = inmuebles.sort((a,b) => (a.precio > b.precio ? 1 : a.precio < b.precio ? -1 : 0))
      }
      
      return inmuebles.map((inmueble) => {
         return(
            <div className="w-full md:w-1/2 lg:w-1/3" key={inmueble.id}>
               <div className="tarjeta m-2 contenedor sm-2 rounded shadow-xl shadow-inner border-solid border-4 border-gray-300">
                  <Link className="centrado w-full h-full text-transparent text-center hover:bg-black hover:opacity-50 hover:text-white z-10" to={"/inmueble/" + inmueble.id}>
                     <div className="centrado self-center text-5xl">Más Información</div>
                  </Link>
                  {/* <Link className="absolute content-center w-full h-full text-transparent text-center hover:bg-black hover:opacity-50 hover:text-white z-10" to={"/inmueble/" + inmueble.folio} target="_blank">
                     <div className="relative py-32 md:py-24 lg:py-20 xl:py-24 text-5xl md:text-4xl">Más Información</div>
                  </Link> */}
                  <div className="relative hover:bg-black">
                     <Imagen url={inmueble.url} />
                     {/* <Imagen className="" url={inmueble.url} /> */}
                     {/* <div className='h-64' styles={{ backgroundImage:`url(${inmueble.url})` }}>
                        <img src={inmueble.url}  alt="Primera imagen" />
                     </div> */}
                     {/* <div styles={{ backgroundImage:`url(${inmueble.url})` }} /> */}
                     
                     <div className="absolute top-0 right-0 bg-gray-700 bg-opacity-25 text-white text-2xl rounded-md px-2">
                        {inmueble.folio}
                     </div>
                     <div className="flex flex-wrap justify-around bg-blue-200">
                        <div className="flex pr-2">
                           <img className="h-5 self-center" src="/images/dormitorio.png" alt="Dormitorio" />
                           <div className="text-xl font-black ml-1">{inmueble.recamaras}</div>
                        </div>
                        <div className="flex pr-2">
                           <img className="h-5 self-center" src={"/images/baño.png"} alt="Baño" />
                           <div className="text-xl font-black ml-1">{inmueble.banios}</div>
                        </div>
                        <div className="flex pr-2">
                           <img className="h-5 self-center" src="/images/estacionamiento.png" alt="Estacionamiento" />
                           <div className="text-xl font-black ml-1">{inmueble.estacionamientos}</div>
                        </div>
                        <div className="flex pr-2">
                           <img className="h-5 self-center" src="/images/ubicacion.png" alt="Ubicación" />
                           <div className="text-xl font-black ml-1">{inmueble.superficie}</div>
                           <div className='flex font-black'>
                              <div className="text-xl pl-1 self-center">Mt</div>
                              <div className='text-sm self-start'>2</div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="flex justify-between px-2 bg-blue-500 h-16">
                     <div className='self-center mr-2'>
                        <span className="text-center text-2xl md:text-xl lg:text-xl xl:text-2xl font-semibold text-white">MXN </span>
                        <span className="text-center text-2xl md:text-xl lg:text-xl xl:text-2xl font-semibold text-white">${inmueble.precio.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')}</span>
                     </div>
                     <div className='self-center ml-2'>
                        <span className="text-center text-2xl md:text-xl lg:text-xl xl:text-2xl font-semibold text-white">{inmueble.ubicacion}</span>
                     </div>
                  </div>
               </div>
            </div>
         )
      })
   }
}
 
export default Tarjeta