import React, { Component } from 'react';
import { Redirect, BrowserRouter, Route } from 'react-router-dom'

import Inmuebles from './components/Inmuebles';
import Inmueble from './components/Inmueble';
import Galeria from './components/Galeria';
import Asesores from './components/Asesores';
import Traductor from './Admin'

import AdminPrincipal from './componentes/Principal'
import AdminAsesores from './componentes/Asesores/Asesores'
import AdminInmuebles from './componentes/Inmuebles/Inmuebles'
import AdminClientes from './componentes/Clientes/Clientes'

import Amplify from 'aws-amplify';
import awsconfig from './aws-exports';
Amplify.configure(awsconfig);

class App extends Component{

  state = { redirect: null };

  async componentDidMount(){
    const shareUrl = document.location.href;
    const indexof = shareUrl.indexOf('#')
    const rutaTemporal = shareUrl.substring(indexof+1)
    if(indexof != -1){
      await this.setState({ redirect: rutaTemporal });
    }
  }

  render(){
    return(
        <div className=''>
          <BrowserRouter>
              <Route exact path='/' component={Inmuebles} />
              <Route exact path='/venta' component={Inmuebles} />
              <Route exact path='/renta' component={Inmuebles} />
              <Route exact path='/terrenos' component={Inmuebles} />
              <Route exact path='/inmueble/:id' component={Inmueble} />
              <Route exact path='/asesores' component={Asesores} />
              <Route exact path='/galeria' component={Galeria} />
              <Route exact path='/admin' component={AdminPrincipal} />
              <Route exact path='/admin/inmuebles' component={AdminInmuebles} />
              <Route exact path='/admin/asesores' component={AdminAsesores} />
              <Route exact path='/admin/clientes' component={AdminClientes} />
              {this.state.redirect && (
                <Redirect to={this.state.redirect} />
              )}
          </BrowserRouter>
        </div>
    );
  }
}

export default App;
