import React, { Component } from 'react';

class Asesor extends Component {
   render(){
      const asesores = this.props.data
      return asesores.map((asesor) => {
         return(
            <div className='w-full lg:w-1/2 xl:w-1/3 mt-4'>
               <div className="mx-3 flex bg-white shadow rounded-lg p-4" key={asesor.id}>                  
                  <img className="h-32 w-32 rounded-full mr-6 xl:mr-2" src={asesor.url} />
                  <div className="text-left">
                     <h2 className="text-2xl xl:text-xl">{asesor.nombre}</h2>
                     <div className="text-purple-500">{asesor.descripcion}</div>
                     <div className="text-gray-600">{asesor.correo}</div>
                     <div className="text-gray-600">{asesor.telefono}</div>
                  </div>
               </div>
            </div>
         );
      })
   }
}

export default Asesor