import React, { Component } from 'react'
import Tarjeta from './Tarjeta'
import Layout from './Layout'
import Navbar from './Navbar'

import { getInmueblesPorTipoZona } from '../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify'

class Inmuebles extends Component {

   state = {
      inmuebles: [],
      nextToken: "",
      filtrar: "Ninguno",
      ordenar: "fecha",
      next: false,
   }

   componentDidMount = async () => {
      this.nextInmuebles()
   }

   nextInmuebles = async () => {
      var tipo = ""
      var zona = ""
      
      switch (this.state.filtrar) {
         case "Nuevo Vallarta":
            zona = {eq:"NUEVOVALLARTA"}
            break;
         
         case "Puerto Vallarta":
            zona = {eq:"PUERTOVALLARTA"}
            break;
         
         case "Ninguno":
            zona = null
            break;
            
         default:
            zona = null
            break;
      }

      switch (document.location.pathname) {
         case "/":
         case "/venta":
            tipo = "VENTA"
            break;

         case "/renta":
            tipo = "RENTA"
            break;
         
         case "/terrenos":
            tipo = "TERRENO"
            break;
            
         default:
            tipo = null
            break;
      }

      const result = await API.graphql(graphqlOperation(getInmueblesPorTipoZona, {tipo, zona, limit: 100, sortDirection: "DESC"} ))
      await this.procesar(result.data.getInmueblesPorTipoZona.items)
      this.setState({ nextToken: result.data.getInmueblesPorTipoZona.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   obtenerMas = async () => {
      const nextToken = this.state.nextToken === "" ? null : this.state.nextToken
      const result = await API.graphql(graphqlOperation(getInmueblesPorTipoZona, { nextToken } ))
      await this.procesar(result.data.getInmueblesPorTipoZona.items)
      this.setState({ nextToken: result.data.getInmueblesPorTipoZona.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   procesar = async (lista) => {
      // var lista = []
      // await this.state.inmuebles.forEach(inmueble => {
      //    Storage.list(inmueble.id+'/')
      //    .then(result => {
      //       if(result.length > 0){
      //          // this.obtenerImagen(item,result[0])
      //          Storage.get(result[0].key)
      //          .then(res => {
      //             const input = {
      //                id: inmueble.id,
      //                folio: inmueble.folio,
      //                banios: inmueble.banios,
      //                recamaras: inmueble.recamaras,
      //                estacionamientos: inmueble.estacionamientos,
      //                superficie: inmueble.superficie,
      //                precio: inmueble.precio,
      //                ubicacion: inmueble.ubicacion,
      //                url: res
      //             }
      //             lista = {...lista, input}
      //             console.log(lista);
      //          })
      //          .catch(err => console.log(err));
      //       }
      //    })
      //    .catch(err => console.log(err))
      // })

      lista.forEach(item => {
         Storage.list(item.id+'/')
         .then(result => {
            if(result.length > 0){
               this.obtenerImagen(item,result[0])
            }
            else{
               this.setState({ inmuebles: [...this.state.inmuebles, item] })
            }
         })
         .catch(err => console.log(err))
      })
      await this.setState({ nextToken: lista.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }   
   
   obtenerImagen = async (inmueble,item) => {
      Storage.get(item.key)
      .then(result => {         
         const input = {
            id: inmueble.id,
            folio: inmueble.folio,
            banios: inmueble.banios,
            recamaras: inmueble.recamaras,
            estacionamientos: inmueble.estacionamientos,
            superficie: inmueble.superficie,
            precio: inmueble.precio,
            ubicacion: inmueble.ubicacion,
            createdAt: inmueble.createdAt,
            url: result
         }
         this.setState({ inmuebles: [...this.state.inmuebles, input] })
      })
      .catch(err => console.log(err));
   }

   handleFiltrar = async event => {
      event.preventDefault()
      if(event.target.value === "Nuevo Vallarta" | event.target.value === "Puerto Vallarta" | event.target.value === "Ninguno"){
         await this.setState({ filtrar: event.target.value })
         await this.setState({ inmuebles : [] })
         await this.setState({ ordenar: "fecha" })
         await this.setState({ nextToken : null })
         this.nextInmuebles()
      }
      else if(event.target.value === "Menor Precio"){
         await this.setState({ ordenar: "precio" })
      }
   }

   async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
         await callback(array[index], index, array);
      }
   }

   render(){
      const { inmuebles, ordenar } = this.state
      return(
         <Layout>
            <Navbar />
            <div className="shadow-inner">
               <div className="flex justify-end pt-4 px-5">     
                  <div className="flex">
                     {/* <label className="self-center mr-1" for="filtro">Ubicación:</label> */}
                     {/* bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1 */}
                     <select className='bg-white focus:outline-none focus:shadow-outline border-gray-300 rounded-lg py-2 px-4 block w-full leading-normal my-1' 
                     value={this.state.filtrar} 
                     onChange={this.handleFiltrar}>
                        <option value="Ninguno">Ninguno</option>
                        <option value="Menor Precio">Menor Precio</option>
                        <option value="Nuevo Vallarta">Nuevo Vallarta</option>
                        <option value="Puerto Vallarta">Puerto Vallarta</option>
                     </select>
                  </div>
               </div>
               <div className="flex flex-wrap justify-center p-2">
                  <Tarjeta data={inmuebles} orden={ordenar}></Tarjeta>
               </div>
               { this.state.next && (
                  <div className=''>
                     <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.obtenerMas}>Mostrar más</button>
                  </div>
               ) }
            </div>
         </Layout>
      );
   }
}

export default Inmuebles