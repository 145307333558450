import React, { Component } from 'react'
import Gallery from 'react-photo-gallery'
import Layout from './Layout'
import Navbar from './Navbar'

import { API, graphqlOperation, Storage } from 'aws-amplify'
import { listClientes } from '../graphql/queries'

class Galeria extends Component {

   state = {
      clientes: [],
      nextToken: "",
      next: false
   }

   componentDidMount = async () => {
      this.nextClientes()
   }

   nextClientes = async () => {
      const nextToken = this.state.nextToken === "" ? null : this.state.nextToken
      const result = await API.graphql(graphqlOperation(listClientes, { nextToken }))
      this.procesar(result.data.listClientes)
   }

   procesar = async (lista) => {
      lista.items.forEach(item => {
         this.obtenerImagen(item)
      })
      await this.setState({ nextToken: lista.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   obtenerImagen = async (objeto) => {
      Storage.get(objeto.urlImagen)
      .then(result => {
         const input = {
            width: parseInt(objeto.ancho),
            height: parseInt(objeto.alto),
            src: result
         }
         this.setState({ clientes: [...this.state.clientes, input] })
      })
      .catch(err => console.log(err));
   }

   render(){

      const { clientes } = this.state

      return(
         <Layout>
            <Navbar />
            <div className=''>
               <div className='m-4'>
                  <Gallery photos={clientes} direction="row" />
               </div>
               { this.state.next && (
                  <div className=''>
                     <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextAsesores}>Mostrar más</button>
                  </div>
               ) }
            </div>
         </Layout>
      );
   }
}

export default Galeria