import React, { Component } from 'react';
import FileBase64 from 'react-file-base64';
import { Storage } from 'aws-amplify';
import { createImagen } from '../../../graphql/mutations';
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class Agregar extends Component {

   state = {
      subir : false,
      spinner : false,
      imagenes : []
   }

   async handleImagenes(files){
      await this.setState({ imagenes : files })
      await this.setState({ subir : !this.state.subir })
   }
   
   async handleCargar(inmueble){
      await this.setState({ subir : !this.state.subir })
      await this.handleAgregar(inmueble)
   }
   
   async handleAgregar(inmueble){
      this.setState({ spinner : true })
      const imagenes = this.state.imagenes
      await this.asyncForEach(imagenes, async (imagen) => {
         await Storage.put(inmueble.id+'/'+imagen.file.name, imagen.file, {
            contentType: imagen.file.type
         })
         .then (result => {})
         .catch(err => console.log(err)); 
       });
      await this.setState({ imagenes : [] })
      this.props.handleRecargar()
      this.setState({ spinner : false })
   }

   async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
         await callback(array[index], index, array);
      }
   }

   render(){
      const data = this.props.data
      return(
         <>
            <Loader
            type="Puff"
            color="#00BFFF"
            height={100}
            width={100}
            visible={this.state.spinner}
            />
            <div className='flex justify-between'>
               <FileBase64 className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1' multiple={ true } onDone={ this.handleImagenes.bind(this) } />
               { this.state.subir && (
                  <button className='bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1' onClick={() => this.handleCargar(data)}>Subir</button>
               )}
            </div>
         </>
      );
   }
}

export default Agregar