import React, { Component } from 'react';
import Modificar from './Modificar'
import Eliminar from './Eliminar'

class AsesoresTabla extends Component {

   handleRecargar = async () => {
      this.props.handleRecargar()
   }   

   render(){
      
      const asesores = this.props.asesores

      return asesores.map((asesor) => {
         return(
            <tr key={asesor.id}>
               <td className="border px-4 py-2">
                  <img src={ asesor.url } />
               </td>
               <td className="border px-1 py-2">{ asesor.nombre }</td>
               <td className="border px-1 py-2">{ asesor.telefono }</td>
               <td className="border px-1 py-2">{ asesor.correo }</td>
               <td className="border px-1 py-2">{ asesor.descripcion }</td>
               <td className="border px-1 py-2 text-center">
                  <div className='flex justify-around'>
                     <Modificar data={asesor} handleRecargar={this.handleRecargar} />
                     <Eliminar data={asesor} handleRecargar={this.handleRecargar} />
                  </div>
               </td>
            </tr>
         )
      })

   }
}

export default AsesoresTabla