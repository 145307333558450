import React, { Component } from 'react';
import { FaImages } from 'react-icons/fa'
import { API, graphqlOperation, Storage } from 'aws-amplify';
import { getInmueble, getImagen } from '../../../graphql/queries';
import Imagen from './Imagen'
import Agregar from './Agregar';
import './style.css'
import Loader from 'react-loader-spinner'
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css"

class Imagenes extends Component {

   state = {
      verModal : false,
      id: "",
      inmueble: "",
      imagenes: [],
      spinner : false
      // nextToken: "",
      // next: false
   }   

   handleModal = async (data) => {
      const inmueble = data
      await this.setState({ inmueble })
      this.obtenerImagenes()
      this.setState({ verModal: !this.state.verModal})
   }
   
   obtenerImagenes = async () => {
      this.setState({ spinner : true })
      const id = this.state.inmueble.id
      Storage.list(id+'/')
     .then(result => {
        result.forEach( item => {
         this.obtenerImagen(item)
          })
       })
     .catch(err => console.log(err));     
     this.setState({ spinner : false })
   }
   
   obtenerImagen = async (item) => {
      const imagenes = [...this.state.imagenes]
      var obtener = true
      for(let imagen of imagenes){
         if(imagen.key === item.key){
            obtener = false
         }
      }
      if(obtener){
         Storage.get(item.key)
         .then(result => {
            const imagen = {
               eTag: item.eTag,
               key: item.key,
               url: result
            }
            this.setState({ imagenes : [...this.state.imagenes, imagen]})
         })
         .catch(err => console.log(err));
      }        
   }

   handleCerrar = async () => {
      // this.props.handleRecargar()
      this.setState({ verModal: !this.state.verModal})
   }
   
   handleRecargar = async () => {
      this.obtenerImagenes()
   }
   
   handleRecargarEliminado = async (data) => {
      this.setState({imagenes: this.state.imagenes.filter(function(imagen) { 
         return imagen !== data 
     })});
   }


   render(){
      const data = this.props.data
      const { inmueble, imagenes} = this.state
      return(
         <>
            <div className='flex justify-center'>
               <button className="text-green-500 hover:text-green-600 focus:outline-none m-1" onClick={() => this.handleModal(data)}>
                  <FaImages />
               </button>
            </div> 

            { this.state.verModal && (
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-full">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                           {/*header*/}
                           <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                              <h3 className="text-3xl font-semibold">Imagenes: {inmueble.titulo}</h3>
                              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={this.handleCerrar}>
                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                              </button>
                           </div>
                           {/*body*/}
                           <div className="relative p-6 flex-auto">
                              <div className="my-4 text-gray-600 text-lg leading-relaxed">                                 
                              <div className='shadow m-2'>
                                 <div className='flex justify-center text-xl'>
                                    <div className="flex flex-wrap justify-between overflow-auto max-h-70">
                                    <Loader
                                    type="Puff"
                                    color="#00BFFF"
                                    height={100}
                                    width={100}
                                    visible={this.state.spinner}
                                    />
                                       <Imagen data={imagenes} handleRecargarEliminado={this.handleRecargarEliminado} />
                                    </div>               
                                 </div>
                                 {/* { this.state.next && (
                                    <div className=''>
                                       <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextImagenes}>Mostrar más</button>
                                    </div>
                                 ) } */}
                              </div>
                              </div>
                           </div>
                           {/*footer*/}
                           <div className="flex p-6 border-t border-solid border-gray-300 rounded-b">
                              <Agregar data={inmueble} handleRecargar={this.handleRecargar}/>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               </>
            )}
         </>
      );
   }
}

export default Imagenes