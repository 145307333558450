import React, { Component } from 'react'

class Anio extends Component {
   render(){
      return (
         <div>{(new Date().getFullYear())}</div>
      )
   }
}
 
export default Anio