import React, { Component } from 'react';
import { FaEdit } from 'react-icons/fa'
import FileBase64 from 'react-file-base64';
import { API, graphqlOperation } from 'aws-amplify'
import { updateCliente } from '../../graphql/mutations';

class Modificar extends Component {

   state = {
      verModal : false,
      cliente : {
         id:"",
         ancho: "",
         alto: ""
      }
   }

   handleModal = (cliente) => {      
      this.setState({ cliente })
      this.setState({ verModal: !this.state.verModal})
   }

   handleAncho = event => { 
      this.setState({ 
         cliente: {...this.state.cliente, ancho: event.target.value}
      })
   }
   
   handleAlto = event => { 
      this.setState({ 
         cliente: {...this.state.cliente, alto: event.target.value}
      })
   }

   handleAgregar = async event => {
      const input = {
         id: this.state.cliente.id,
         ancho: this.state.cliente.ancho,
         alto: this.state.cliente.alto
      }

      await API.graphql(graphqlOperation(updateCliente, { input }))
      this.setState({ cliente: {...this.state.cliente, ancho: "" } })
      this.setState({ cliente: {...this.state.cliente, alto: "" } })

      this.props.handleRecargar()
      this.setState({ verModal: !this.state.verModal})
   }

   render(){
      const data = this.props.data
      return(
         <>
            <div className='flex justify-center'>
               <button className="text-green-500 hover:text-green-600 focus:outline-none m-1" onClick={() => this.handleModal(data)}>
                  <FaEdit />
               </button>
            </div> 

            { this.state.verModal && (
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                           {/*header*/}
                           <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                              <h3 className="text-3xl font-semibold">Agregar Cliente</h3>
                              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={this.handleModal}>
                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                              </button>
                           </div>
                           {/*body*/}
                           <div className="relative p-6 flex-auto">
                              <div className="my-4 text-gray-600 text-lg leading-relaxed">                                 
                                 <form>
                                    <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                       type="number" 
                                       placeholder="Ancho" 
                                       name="clienteAncho" 
                                       value={this.state.cliente.ancho}
                                       onChange={this.handleAncho} />
                                    <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                       type="number" 
                                       placeholder="Alto" 
                                       name="clienteAlto" 
                                       value={this.state.cliente.alto}
                                       onChange={this.handleAlto} />                                    
                                 </form>
                              </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                              <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleModal}>Cancelar</button>
                              <button className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleAgregar}>Guardar</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               </>
            )}
         </>
      );
   }
}

export default Modificar