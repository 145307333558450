import React, { Component } from 'react'
import ImageGallery from 'react-image-gallery';
import "react-image-gallery/styles/css/image-gallery.css";
import { FacebookMessengerShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerIcon, WhatsappIcon, EmailIcon } from "react-share";
import Layout from './Layout'
import Navbar from './Navbar'

import { getInmueble } from '../graphql/queries';
import { API, graphqlOperation, Storage } from 'aws-amplify'

class Inmueble extends Component {

   state = {
      id: "",
      inmueble: "",      
      urlActual: "",
      imagenes: []
   }

   async componentDidMount(){
      window.scrollTo(0, 0)
      const protocolo = window.location.protocol
      const urldominio = window.location.hostname
      const path = window.location.pathname

      const urlActual = protocolo + "//" + urldominio + "/#" + path

      this.setState({ urlActual })

      const id = this.props.match.params.id
      await this.setState({ id })
      await this.obtenerInmueble()
      await this.obtenerImagenes()
   }

   async obtenerInmueble(){
      const id = this.state.id
      const result = await API.graphql(graphqlOperation(getInmueble, { id }))
      await this.setState({ inmueble : result.data.getInmueble })
   }
   
   async obtenerImagenes(){
      const id = this.state.id
      Storage.list(id+'/')
         .then(res => {
            res.sort((a,b) => (a.key > b.key ? 1 : a.key < b.key ? -1 : 0))
            res.forEach(item => {
               this.obtenerImagen(item)               
            });
         })
         .catch(err => console.log(err))
   }

   async obtenerImagen(item){
      Storage.get(item.key)
      .then(result => {
         const input = {
            original: result,
            thumbnail: result
         }
         this.setState({ imagenes: [...this.state.imagenes, input] })
      })
      .catch(err => console.log(err));
   }

   render(){
      const { inmueble, folio, urlActual } = this.state

      const title = 'InmobiliariaYes';

      const { imagenes } = this.state

      return(
         <Layout>
            <Navbar />
            <div id="up" className="p-2 sm:p-8 shadow-inner">
               <div>
                  <div className="flex flex-wrap justify-center">
                     <div className="max-w-full md:max-w-screen-md">
                        <ImageGallery items={imagenes} />
                     </div>
                     <div className='mx-4 p-8 shadow bg-white h-auto self-center'>
                        <div className="text-3xl text-center">
                           <div className=''>
                             {inmueble.titulo}
                           </div>
                        </div>
                        <div className="text-5xl text-center font-bold pt-8">${parseFloat(inmueble.precio).toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}</div>
                        <div className="text-3xl text-center">{inmueble.ubicacion}</div>
                        <div className='text-xl pt-8 text-center'>Compartir propiedad:</div>
                        <div className="flex flex-wrap justify-center">
                           <div className='mx-1'>
                           <WhatsappShareButton url={urlActual} quote={title}>
                              <WhatsappIcon size={48} round />
                           </WhatsappShareButton>
                           </div>
                           <div className='mx-1'>
                           <FacebookMessengerShareButton url={urlActual} quote={title} appId="667052410553622">
                              <FacebookMessengerIcon size={48} round />
                           </FacebookMessengerShareButton>
                           </div>
                           <div className='mx-1'>
                           <EmailShareButton url={urlActual} quote={title}>
                              <EmailIcon size={48} round />
                           </EmailShareButton>
                           </div>
                        </div>
                     </div>
                  </div>
                  {/* <div className='border border-2 border-black my-2'></div> */}
                  <div className="my-4 sm:mx-4 p-4 shadow bg-white">
                     <div className="text-3xl text-left">Detalles Generales</div>
                     <div className="flex flex-wrap justify-start sm:p-8">
                        <div className='sm:text-left flex m-4'>
                           <img className="h-8 self-center self-center" src="/images/dormitorio.png" alt="Dormitorios" />
                           <div className="text-xl px-1 self-center">Recamara</div>
                           <div className='text-3xl pl-2 text-bold'>{inmueble.recamaras}</div>
                        </div>
                        <div className='sm:text-left flex m-4'>
                           <img className="h-8 self-center self-center" src="/images/baño.png" alt="Baños" />
                           <div className="text-xl px-1 self-center">Baño</div>
                           <div className='text-3xl pl-2 text-bold'>{inmueble.banios}</div>
                        </div>
                        <div className='sm:text-left flex m-4'>
                           <img className="h-8 self-center self-center" src="/images/estacionamiento.png" alt="Estacionamientos" />
                           <div className="text-xl px-1 self-center">Estacionamiento</div>
                           <div className='text-3xl pl-2 text-bold'>{inmueble.estacionamientos}</div>
                        </div>
                        <div className='sm:text-left flex m-4'>
                           <img className="h-8 self-center self-center" src="/images/ubicacion.png" alt="Superfície" />
                           <div className="flex">
                              <div className="text-xl pl-1 self-center">Superficie</div>
                           <div className='text-3xl pl-2 text-bold'>{inmueble.superficie}</div>
                              <div className='flex'>
                                 <div className="text-xl pl-1 self-center">mt</div>
                                 <div className='text-sm self-start'>2</div>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>            
                  <div className="my-4 sm:mx-4 p-4 shadow bg-white">
                     <div className="text-3xl text-left">Descripción</div>
                     <div className="flex flex-wrap justify-start sm:p-8">
                        <div className='sm:flex sm:flex-wrap p-4 text-justify'>
                           <td dangerouslySetInnerHTML={{__html: inmueble.descripcion}} />
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </Layout>
      );
   }
}

export default Inmueble