import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { listAsesors } from '../../graphql/queries'
import AsesoresTabla from './AsesoresTabla'
import Asesor from './Asesor'
import './Asesores.css'
import Layout from '../Layout'

class Asesores extends Component {

   state = {
      asesores: [],
      nextToken: "",
      next: false
   }

   componentDidMount = async () => {
      this.getAsesors()
   }

   async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
         await callback(array[index], index, array);
      }
   }

   getAsesors = async () => {
      this.setState({ asesores : []})
      const result = await API.graphql(graphqlOperation(listAsesors))
      await this.asyncForEach(result.data.listAsesors.items, async (item) => {
         await Storage.get(item.urlImagen)
         .then(result => {
            const asesor = {
               id: item.id,
               nombre: item.nombre,
               telefono: item.telefono,
               correo: item.correo,
               descripcion: item.descripcion,
               urlImagen: item.urlImagen,
               url: result,
            }
            this.setState({ asesores: [...this.state.asesores, asesor] })
         })
         .catch(err => {
            this.setState({ asesores: [...this.state.asesores, item] })
         });         
      });
      this.setState({ nextToken: result.data.listAsesors.nextToken })
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }


   nextClientes = async () => {
      const nextToken = this.state.nextToken

      const result = await API.graphql(graphqlOperation(listAsesors, { nextToken } ))
      await this.asyncForEach(result.data.listAsesors.items, async (item) => {
         await Storage.get(item.id+'/perfil.png')
         .then(result => {
            const asesor = {
               id: item.id,
               nombre: item.nombre,
               telefono: item.telefono,
               correo: item.correo,
               descripcion: item.descripcion,
               url: result,
            }
            this.setState({ asesores: [...this.state.asesores, asesor] })
         })
         .catch(err => {
            this.setState({ asesores: [...this.state.asesores, item] })
         });         
      });
      this.setState({ nextToken: result.data.listAsesors.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   handleRecargar = async () => {
      this.getAsesors()
   }
   
   render(){
      const { asesores } = this.state
      
      return(
         <Layout>
            <div className='shadow m-2'>
               <Asesor handleRecargar={this.handleRecargar} />
               <div className='flex justify-center text-xl'>
                  <table className='table-auto w-full'>
                     <thead>
                        <tr className="bg-indigo-600 text-white">
                           <th className="border px-1 py-2">Imagen</th>
                           <th className="border px-1 py-2">Nombre</th>
                           <th className="border px-1 py-2">Teléfono</th>
                           <th className="border px-1 py-2">Correo Electrónico</th>
                           <th className="border px-1 py-2">Descripcion</th>
                           <th className="border px-1 py-2">Acciones</th>
                        </tr>
                     </thead>
                     <tbody className="">
                        <AsesoresTabla asesores={asesores} handleRecargar={this.handleRecargar} />
                     </tbody>
                  </table>
               </div>
               { this.state.next && (
                  <div className=''>
                     <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextClientes}>Mostrar más</button>
                  </div>
               ) }
            </div>
         </Layout>
      )
   }
}

export default Asesores