import React, { Component } from 'react';
import { listInmuebles } from '../../graphql/queries';
import { API, graphqlOperation } from 'aws-amplify'
import Agregar from './Agregar'
import InmueblesTabla from './InmueblesTabla'
import Layout from '../Layout'

class Inmuebles extends Component {

   state = {
      inmuebles: [],
      nextToken: "",
      next: false,
   }

   componentDidMount = async () => {
      this.getInmuebles()
   }

   getInmuebles = async () => {
      const result = await API.graphql(graphqlOperation(listInmuebles))
      this.setState({ inmuebles: result.data.listInmuebles.items })
      this.setState({ nextToken: result.data.listInmuebles.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   nextInmuebles = async () => {
      const nextToken = this.state.nextToken

      const result = await API.graphql(graphqlOperation(listInmuebles, { nextToken } ))
      result.data.listInmuebles.items.forEach(cliente =>
         this.setState({ inmuebles: [...this.state.inmuebles, cliente] })         
      )
      this.setState({ nextToken: result.data.listInmuebles.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   handleRecargar = async () => {
      this.getInmuebles()
   }

   render(){
      const { inmuebles } = this.state

      return(
         <Layout>
            <div className='shadow m-2'>            
               <Agregar handleRecargar={this.handleRecargar} />
               <div className='flex justify-center text-xl'>
                  <table className='table-auto w-full'>
                     <thead>
                        <tr className="bg-indigo-600 text-white">
                           <th className="border px-1 py-2">Folio</th>
                           <th className="border px-1 py-2">Titulo</th>
                           <th className="border px-1 py-2">Precio</th>
                           <th className="border px-1 py-2">Ubicacion</th>
                           <th className="border px-1 py-2">Tipo</th>
                           <th className="border px-1 py-2">Recamaras</th>
                           <th className="border px-1 py-2">Banios</th>
                           <th className="border px-1 py-2">Estacionamientos</th>
                           <th className="border px-4 py-2">Superficie</th>
                           <th className="border px-4 py-2">Acciones</th>
                        </tr>
                     </thead>
                     <tbody className="">
                        <InmueblesTabla data={inmuebles} handleRecargar={this.handleRecargar} />
                     </tbody>
                  </table>
               </div>
               { this.state.next && (
                  <div className=''>
                     <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextInmuebles}>Mostrar más</button>
                  </div>
               ) }
            </div>
         </Layout>
      );
   }
}

export default Inmuebles