import { Translations } from "@aws-amplify/ui-components";
import { I18n } from 'aws-amplify';

I18n.putVocabulariesForLanguage("es-ES", {
  [Translations.BACK_TO_SIGN_IN]: 'Regresar a Iniciar sesión',
  [Translations.CHANGE_PASSWORD_ACTION]: 'Cambio',
  [Translations.CHANGE_PASSWORD]: 'Cambia la contraseña',
  [Translations.CODE_LABEL]: 'Código de verificación',
  [Translations.CODE_PLACEHOLDER]: 'Introduzca el código',
  [Translations.CONFIRM_SIGN_UP_CODE_LABEL]: 'Código de confirmación',
  [Translations.CONFIRM_SIGN_UP_CODE_PLACEHOLDER]: 'Ingrese su código',
  [Translations.CONFIRM_SIGN_UP_HEADER_TEXT]: 'Confirmar Registrarse',
  [Translations.CONFIRM_SIGN_UP_LOST_CODE]: '¿Perdiste tu código?',
  [Translations.CONFIRM_SIGN_UP_RESEND_CODE]: 'Reenviar codigo',
  [Translations.CONFIRM_SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Confirmar',
  [Translations.CONFIRM_SMS_CODE]: 'Confirmar código de SMS',
  [Translations.CONFIRM_TOTP_CODE]: 'Confirmar código TOTP',
  [Translations.CONFIRM]: 'Confirmar',
  [Translations.CREATE_ACCOUNT_TEXT]: 'Crear una cuenta',
  [Translations.EMAIL_LABEL]: 'Dirección de correo electrónico *',
  [Translations.EMAIL_PLACEHOLDER]: 'Ingrese su dirección de correo electrónico',
  [Translations.FORGOT_PASSWORD_TEXT]: '¿Olvidaste tu contraseña?',
  [Translations.LESS_THAN_TWO_MFA_VALUES_MESSAGE]: 'Menos de dos tipos de mfa disponibles',
  [Translations.NEW_PASSWORD_LABEL]: 'Nueva contraseña',
  [Translations.NEW_PASSWORD_PLACEHOLDER]: 'Introduzca su nueva contraseña',
  [Translations.NO_ACCOUNT_TEXT]: 'Sin cuenta?',
  [Translations.PASSWORD_LABEL]: 'Contraseña *',
  [Translations.PASSWORD_PLACEHOLDER]: 'Ingresa tu contraseña',
  [Translations.PHONE_LABEL]: 'Número de teléfono *',
  [Translations.PHONE_PLACEHOLDER]: '(555) 555-1212',
  [Translations.QR_CODE_ALT]: 'Código QR',
  [Translations.RESET_PASSWORD_TEXT]: 'Restablecer la contraseña',
  [Translations.RESET_YOUR_PASSWORD]: 'Restablecer su contraseña',
  [Translations.SELECT_MFA_TYPE_HEADER_TEXT]: 'Seleccionar tipo de MFA',
  [Translations.SELECT_MFA_TYPE_SUBMIT_BUTTON_TEXT]: 'Verificar',
  [Translations.SEND_CODE]: 'Enviar código',
  [Translations.SUBMIT]: 'Enviar',
  [Translations.SETUP_TOTP_REQUIRED]: 'TOTP necesita ser configurado',
  [Translations.SIGN_IN_ACTION]: 'Registrarse',
  [Translations.SIGN_IN_HEADER_TEXT]: 'Iniciar sesión en su cuenta',
  [Translations.SIGN_IN_TEXT]: 'Registrarse',
  [Translations.SIGN_IN_WITH_AMAZON]: 'Inicia sesión con Amazon',
  [Translations.SIGN_IN_WITH_AUTH0]: 'Inicie sesión con Auth0',
  [Translations.SIGN_IN_WITH_AWS]: 'Inicie sesión con AWS',
  [Translations.SIGN_IN_WITH_FACEBOOK]: 'Iniciar sesión usando Facebook',
  [Translations.SIGN_IN_WITH_GOOGLE]: 'Inicia sesión con Google',
  [Translations.SIGN_OUT]: 'Desconectar',
  [Translations.SIGN_UP_EMAIL_PLACEHOLDER]: 'Email',
  [Translations.SIGN_UP_HAVE_ACCOUNT_TEXT]: '¿Tener una cuenta?',
  [Translations.SIGN_UP_HEADER_TEXT]: 'Crea una cuenta nueva',
  [Translations.SIGN_UP_PASSWORD_PLACEHOLDER]: 'Contraseña',
  [Translations.SIGN_UP_SUBMIT_BUTTON_TEXT]: 'Crear una cuenta',
  [Translations.SIGN_UP_USERNAME_PLACEHOLDER]: 'Nombre de usuario',
  [Translations.SUCCESS_MFA_TYPE]: '¡Éxito! Su tipo de MFA es ahora:',
  [Translations.TOTP_HEADER_TEXT]: 'Escanee y luego ingrese el código de verificación',
  [Translations.TOTP_LABEL]: 'Ingrese el código de seguridad:',
  [Translations.TOTP_ISSUER]: 'AWSCognito',
  [Translations.TOTP_SETUP_FAILURE]: 'La configuración de TOTP ha fallado',
  [Translations.TOTP_SUBMIT_BUTTON_TEXT]: 'Verificar token de seguridad',
  [Translations.TOTP_SUCCESS_MESSAGE]: '¡Configura TOTP con éxito!',
  [Translations.UNABLE_TO_SETUP_MFA_AT_THIS_TIME]: '¡Ha fallado! No se puede configurar MFA en este momento',
  [Translations.USERNAME_LABEL]: 'Nombre de usuario *',
  [Translations.USERNAME_PLACEHOLDER]: 'Ingrese su nombre de usuario',
  [Translations.VERIFY_CONTACT_EMAIL_LABEL]: 'Email',
  [Translations.VERIFY_CONTACT_HEADER_TEXT]: 'La recuperación de la cuenta requiere información de contacto verificada',
  [Translations.VERIFY_CONTACT_PHONE_LABEL]: 'Número de teléfono',
  [Translations.VERIFY_CONTACT_SUBMIT_LABEL]: 'Enviar',
  [Translations.VERIFY_CONTACT_VERIFY_LABEL]: 'Verificar',
  [Translations.ADDRESS_LABEL]: 'Habla a',
  [Translations.ADDRESS_PLACEHOLDER]: 'Ingrese su direccion',
  [Translations.NICKNAME_LABEL]: 'Apodo',
  [Translations.NICKNAME_PLACEHOLDER]: 'Ingresa tu alias',
  [Translations.BIRTHDATE_LABEL]: 'Cumpleaños',
  [Translations.BIRTHDATE_PLACEHOLDER]: 'Ingresa tu cumpleaños',
  [Translations.PICTURE_LABEL]: 'URL de imagen',
  [Translations.PICTURE_PLACEHOLDER]: 'Ingrese su URL de imagen',
  [Translations.FAMILY_NAME_LABEL]: 'Apellido',
  [Translations.FAMILY_NAME_PLACEHOLDER]: 'Ingrese su apellido',
  [Translations.PREFERRED_USERNAME_LABEL]: 'Nombre de usuario preferido',
  [Translations.PREFERRED_USERNAME_PLACEHOLDER]: 'Ingrese su nombre de usuario preferido',
  [Translations.GENDER_LABEL]: 'Género',
  [Translations.GENDER_PLACEHOLDER]: 'Ingrese su género',
  [Translations.PROFILE_LABEL]: 'URL del perfil',
  [Translations.PROFILE_PLACEHOLDER]: 'Ingrese su URL de perfil',
  [Translations.GIVEN_NAME_LABEL]: 'Nombre de pila',
  [Translations.GIVEN_NAME_PLACEHOLDER]: 'Ponga su primer nombre',
  [Translations.ZONEINFO_LABEL]: 'Zona horaria',
  [Translations.ZONEINFO_PLACEHOLDER]: 'Ingrese su zona horaria',
  [Translations.LOCALE_LABEL]: 'Lugar',
  [Translations.LOCALE_PLACEHOLDER]: 'Ingrese su localidad',
  [Translations.UPDATED_AT_LABEL]: 'Actualizado en',
  [Translations.UPDATED_AT_PLACEHOLDER]: 'Ingrese la hora en que se actualizó la información por última vez',
  [Translations.MIDDLE_NAME_LABEL]: 'Segundo nombre',
  [Translations.MIDDLE_NAME_PLACEHOLDER]: 'Ingrese su segundo nombre',
  [Translations.WEBSITE_LABEL]: 'Sitio web',
  [Translations.WEBSITE_PLACEHOLDER]: 'Ingrese su sitio web',
  [Translations.NAME_LABEL]: 'Nombre completo',
  [Translations.NAME_PLACEHOLDER]: 'Ingrese su nombre completo',
  [Translations.PHOTO_PICKER_TITLE]: 'Título del selector',
  [Translations.PHOTO_PICKER_HINT]: 'El texto o contenido complementario puede ocupar este espacio aquí',
  [Translations.PHOTO_PICKER_PLACEHOLDER_HINT]: 'Sugerencia de marcador de posición',
  [Translations.PHOTO_PICKER_BUTTON_TEXT]: 'Botón',
  [Translations.IMAGE_PICKER_TITLE]: 'Añadir foto de perfil',
  [Translations.IMAGE_PICKER_HINT]: 'Vista previa de la imagen antes de subir',
  [Translations.IMAGE_PICKER_PLACEHOLDER_HINT]: 'Toque para seleccionar imagen',
  [Translations.IMAGE_PICKER_BUTTON_TEXT]: 'Subir',
  [Translations.PICKER_TEXT]: 'Elige un archivo',
  [Translations.TEXT_FALLBACK_CONTENT]: 'Contenido de reserva'
});

