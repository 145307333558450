import React, { Component } from 'react';
import Eliminar from './Eliminar'
import Modificar from './Modificar'

class ClientesTabla extends Component {

   handleRecargar = async () => {
      this.props.handleRecargar()
   }

   render(){

      const clientes = this.props.clientes

      return clientes.map((cliente) => {
         return(
            <tr key={cliente.id}>
               <td className="border px-4 py-2">
                  <img src={ cliente.url } />
               </td>
               <td className="border px-4 py-2 text-center">{ cliente.ancho }</td>
               <td className="border px-4 py-2 text-center">{ cliente.alto }</td>
               <td className="border px-4 py-2 text-center">
                  <div className='flex justify-around'>
                     <Modificar data={cliente} handleRecargar={this.handleRecargar} />
                     <Eliminar data={cliente} handleRecargar={this.handleRecargar} />
                  </div>
               </td>
            </tr>
         )
      })
   }
}

export default ClientesTabla