/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAsesor = /* GraphQL */ `
  mutation CreateAsesor(
    $input: CreateAsesorInput!
    $condition: ModelAsesorConditionInput
  ) {
    createAsesor(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      correo
      telefono
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const updateAsesor = /* GraphQL */ `
  mutation UpdateAsesor(
    $input: UpdateAsesorInput!
    $condition: ModelAsesorConditionInput
  ) {
    updateAsesor(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      correo
      telefono
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const deleteAsesor = /* GraphQL */ `
  mutation DeleteAsesor(
    $input: DeleteAsesorInput!
    $condition: ModelAsesorConditionInput
  ) {
    deleteAsesor(input: $input, condition: $condition) {
      id
      nombre
      descripcion
      correo
      telefono
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const createCliente = /* GraphQL */ `
  mutation CreateCliente(
    $input: CreateClienteInput!
    $condition: ModelClienteConditionInput
  ) {
    createCliente(input: $input, condition: $condition) {
      id
      ancho
      alto
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const updateCliente = /* GraphQL */ `
  mutation UpdateCliente(
    $input: UpdateClienteInput!
    $condition: ModelClienteConditionInput
  ) {
    updateCliente(input: $input, condition: $condition) {
      id
      ancho
      alto
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const deleteCliente = /* GraphQL */ `
  mutation DeleteCliente(
    $input: DeleteClienteInput!
    $condition: ModelClienteConditionInput
  ) {
    deleteCliente(input: $input, condition: $condition) {
      id
      ancho
      alto
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const createInmueble = /* GraphQL */ `
  mutation CreateInmueble(
    $input: CreateInmuebleInput!
    $condition: ModelInmuebleConditionInput
  ) {
    createInmueble(input: $input, condition: $condition) {
      id
      folio
      titulo
      precio
      ubicacion
      zona
      tipo
      recamaras
      banios
      estacionamientos
      superficie
      descripcion
      createdAt
      updatedAt
    }
  }
`;
export const updateInmueble = /* GraphQL */ `
  mutation UpdateInmueble(
    $input: UpdateInmuebleInput!
    $condition: ModelInmuebleConditionInput
  ) {
    updateInmueble(input: $input, condition: $condition) {
      id
      folio
      titulo
      precio
      ubicacion
      zona
      tipo
      recamaras
      banios
      estacionamientos
      superficie
      descripcion
      createdAt
      updatedAt
    }
  }
`;
export const deleteInmueble = /* GraphQL */ `
  mutation DeleteInmueble(
    $input: DeleteInmuebleInput!
    $condition: ModelInmuebleConditionInput
  ) {
    deleteInmueble(input: $input, condition: $condition) {
      id
      folio
      titulo
      precio
      ubicacion
      zona
      tipo
      recamaras
      banios
      estacionamientos
      superficie
      descripcion
      createdAt
      updatedAt
    }
  }
`;
