import React, { Component } from 'react'
import Navbar from './Navbar'
import { FaHeartbeat } from 'react-icons/fa'
import './Layout.css'

import { Authenticator, Greetings, SignIn, ConfirmSignIn, RequireNewPassword, ConfirmSignUp, VerifyContact, ForgotPassword, TOTPSetup, Loading  } from 'aws-amplify-react';
import '@aws-amplify/ui/dist/style.css';
import { Translations } from "@aws-amplify/ui-components";
import { I18n } from 'aws-amplify';

class Layout extends Component {

   state = {
      sesionIniciada : ""
   }
   
   estatusSesion = async (authState) => {
      const estatus = authState
      this.setState({ sesionIniciada: authState })    
   }
   
   render(){
      return ( 
         <>
            <Authenticator
               authState="signIn"
               onStateChange={(authState) => this.estatusSesion(authState)} 
               hide={ [
                  Greetings,
                  SignIn,
                  ConfirmSignIn,
                  RequireNewPassword,
                  ConfirmSignUp,
                  VerifyContact,
                  ForgotPassword,
                  TOTPSetup,
                  Loading
                  ]}
               hideDefault={true}
            >
               {/* <Greetings
                  inGreeting={(username) => 'Hola ' + username}
                  outGreeting="Iniciar Sesion desde aqui..."
               /> */}
               <SignIn/>
               <ConfirmSignIn/>
               <RequireNewPassword/>
               {/* <SignUp/> */}
               <ConfirmSignUp/>
               <VerifyContact/>
               <ForgotPassword/>
               <TOTPSetup/>
               <Loading/>
            </Authenticator>

            { this.state.sesionIniciada === "signedIn" && (
               <>
                  <div className=''>
                     <Navbar />
                  </div>
                  <div className="min-h-screen m-4 shadow-layout">
                     {this.props.children}
                  </div>
                  <footer className='bg-black text-white text-center'>
                     <div className="w-full">
                        <div className="flex flex-wrap justify-center p-4 azulFranja">
                           <div>Copyright &copy;</div>
                           <div>{(new Date().getFullYear())}.</div>
                           <div className="self-center mx-1">Todos los derechos reservados. Hecha con</div>
                           <FaHeartbeat className="self-center mx-1 text-red-700" />
                           <div className="self-center mx-1">por</div>
                           <a className="self-center" href="https://abc-ti.com" target="_blank" >Abel Castillo</a>
                        </div>
                     </div>
                  </footer>
               </>
            )}
         </>
      );
   }
}
 
export default Layout;