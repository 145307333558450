import React from 'react'
import './Estylo.css'
import { FaPhoneSquareAlt } from 'react-icons/fa'

const Sidebar = () => {
   return ( 
      <div className="sm:flex flex-wrap justify-center md:justify-around">
         <div className='flex justify-center'>
            <a className="self-center" href="/">
               <img className="h-40 md:h-32 lg:h-40" src="/images/logo_transparente.png" alt="Inmobiliaria Yes" />
            </a>
         </div>
         <div className="text-center self-center sm:mx-2">
            <div className='hidden uppercase md:flex text-xl md:text-2xl lg:text-3xl font-bold justify-center'>Tramitamos tu crédito</div>
            <div className='flex justify-between w-full'>
               <img className="hidden self-center md:flex md:h-20 lg:h-24" src="/images/fovissste.png" alt="Fovissste" />
               <img className="hidden self-center md:flex md:h-24 lg:h-32 mx-2 lg:mx-6" src="/images/infonavit.png" alt="Infonavit" />
               <img className="hidden self-center md:flex md:h-24 lg:h-32 mt-4" src="/images/credito.png" alt="Credito Hipotecario" />
            </div>
         </div>
         <div className="self-center font-bold">
            <div className='flex justify-center sm:block'>
               <div className='flex justify-center mx-1'>
                  <div className="flex justify-center text-md sm:text-3xl md:text-2xl lg:text-3xl text-center azulBoton rounded-full my-1 px-2">
                     <FaPhoneSquareAlt className="self-center text-white" />
                     <a className="text-white ml-1" target="_blank" href="tel:+523221463728">322-146-3728</a>
                  </div>
               </div>
               <div className='flex justify-center mx-1'>
                  <div className="flex justify-center text-md sm:text-3xl md:text-2xl lg:text-3xl text-center azulBoton rounded-full my-1 px-2">
                     <FaPhoneSquareAlt className="self-center text-white" />
                     <a className="text-white ml-1" target="_blank" href="tel:+523222753755">322-275-3755</a>
                  </div>
               </div>
            </div>
            <div className="text-md text-center">Contáctanos por Whatsapp</div>
            <div className='flex justify-center'>
               <div className="flex justify-center verdeWhatsApp py-1 px-2 rounded-full">
                  <img className="self-center h-6 sm:h-8 md:h-6 lg:h-8 pr-1" src="/images/whatsapp.png" alt="Inmobiliaria Yes" />
                  <a className="block text-center text-md sm:text-2xl md:text-base lg:text-2xl text-white" target="_blank" href="https://bit.ly/2vzLaqO">https://bit.ly/2vzLaqO</a>
               </div>
            </div>
         </div>
         <div className="md:hidden text-center w-full">
            <div className='uppercase text-2xl sm:text-4xl font-bold'>Tramitamos tu crédito</div>
            <div className='flex justify-center'>
               <img className="self-center h-16 sm:h-24" src="/images/fovissste.png" alt="Fovissste" />
               <img className="self-center md:flex h-20 sm:h-32 mx-2 sm:mx-1" src="/images/infonavit.png" alt="Infonavit" />
               <img className="self-center md:flex h-20 sm:h-32 mt-4" src="/images/credito.png" alt="CreditoHipotecario" />
            </div>
         </div>
      </div>
    );
}
 
export default Sidebar;