import React, { Component } from 'react';
import FileBase64 from 'react-file-base64';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { createCliente, updateCliente } from '../../graphql/mutations';
import { FaPlusSquare } from 'react-icons/fa'

class Cliente extends Component {

   state = {
      verModal : false,
      cliente : {
         ancho: "",
         alto: "",
         urlImagen: ""
      },
      file: ""
   }

   handleModal = () => {
      this.setState({ verModal: !this.state.verModal})
   }

   handleImagen(file) {
      this.setState({ file: file.file })
   }

   handleAncho = event => {
      this.setState({ 
         cliente: {...this.state.cliente, ancho: event.target.value}
      })
   }
   
   handleAlto = event => {
      this.setState({ 
         cliente: {...this.state.cliente, alto: event.target.value}
      })
   }

   handleAgregar = async event => {
      event.preventDefault()

      const input = {
         ancho: this.state.cliente.ancho,
         alto: this.state.cliente.alto
      }

      const result = await API.graphql(graphqlOperation(createCliente, { input }))

      const id = result.data.createCliente.id
      const file = this.state.file      
      Storage.put('clientes/'+id+'/'+file.name, file, {
         contentType: file.type
      })
      .then (async res => {
         const input2 = {
            id: id,
            urlImagen: res.key
         }
         const resUpdate = await API.graphql(graphqlOperation(updateCliente, { input: input2 }))
         this.setState({ cliente: {...this.state.cliente, urlImagen: "" } })
         this.setState({ cliente: {...this.state.cliente, ancho: 0 } })
         this.setState({ cliente: {...this.state.cliente, alto: 0 } })
         this.setState({ file: "" })
         this.props.handleRecargar()
         this.handleModal()
      })
      .catch(err => console.log(err));     
   }

   render(){
      return(
         <>
            <div className='flex justify-between'>
               <div className='text-blue-700 text-5xl text-center px-4'>Clientes</div>
               <div className='self-center'>
                  <button className="text-blue-700 bg-blue-100 hover:bg-blue-200 hover:text-blue-800 focus:outline-none rounded-md text-3xl mx-4 px-2" onClick={this.handleModal}>
                     <div className='flex'>
                        <FaPlusSquare className='self-center' />
                        <div className=''>Agregar Cliente</div>
                     </div>
                  </button>
               </div>
            </div>

            { this.state.verModal && (
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                           {/*header*/}
                           <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                              <h3 className="text-3xl font-semibold">Agregar Cliente</h3>
                              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={this.handleModal}>
                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                              </button>
                           </div>
                           {/*body*/}
                           <div className="relative p-6 flex-auto">
                              <div className="my-4 text-gray-600 text-lg leading-relaxed">                                 
                                 <form>
                                    <FileBase64 className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1' multiple={ false } onDone={ this.handleImagen.bind(this) } />
                                    <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                       type="number" 
                                       placeholder="Ancho" 
                                       name="clienteAncho" 
                                       value={this.state.cliente.ancho}
                                       onChange={this.handleAncho} />
                                    <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                       type="number" 
                                       placeholder="Alto" 
                                       name="clienteAlto" 
                                       value={this.state.cliente.alto}
                                       onChange={this.handleAlto} />                                    
                                 </form>
                              </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                              <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleModal}>Cancelar</button>
                              <button className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleAgregar}>Guardar</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               </>
            )}
         </>
      );
   }
}

export default Cliente