import React, { Component } from 'react';
import { deleteImagen } from '../../../graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { FaTrashAlt } from 'react-icons/fa'

class Eliminar extends Component {

   handleModalEliminar = async data => {

      Storage.remove(data.key)
      .then(result => {
         this.props.handleRecargarEliminado(data)
      })
      .catch(err => console.log(err));
   }

   render(){
      const data = this.props.data
      return(
         <button className="text-red-500 hover:text-red-600 focus:outline-none m-1" onClick={ () => this.handleModalEliminar(data)}>
            <FaTrashAlt />
         </button>
      );
   }
}

export default Eliminar