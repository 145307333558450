import React, { Component } from 'react';
import { NavLink } from 'react-router-dom'

import { SignOut } from 'aws-amplify-react';
// import './Navbar.css'

class Navbar extends Component {
   render(){
      return(
         <div>
            <nav>
               <div className='flex justify-between bg-black text-white font-bold'>
                  <a className='self-center text-3xl mx-4'>Inmobiliaria Yes</a>
                  <div className=''>
                     <div className='flex justify-end'>
                        <SignOut />
                     </div>
                     <div className='self-end flex justify-end my-4 mx-4'>
                        <NavLink className='font-bold hover:text-blue-200 uppercase rounded-lg p-2 mx-1' exact to='/'>Principal</NavLink>
                        <NavLink className='font-bold hover:text-blue-200 uppercase rounded-lg p-2 mx-1' to='/admin/inmuebles'>Inmuebles</NavLink>
                        <NavLink className='font-bold hover:text-blue-200 uppercase rounded-lg p-2 mx-1' to='/admin/asesores'>Asesores</NavLink>
                        <NavLink className='font-bold hover:text-blue-200 uppercase rounded-lg p-2 mx-1' to='/admin/clientes'>Clientes</NavLink>
                     </div>
                  </div>
               </div>
            </nav>
         </div>
      );
   }
}

export default Navbar