import React, { Component } from 'react';
import { deleteInmueble } from '../../graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import {  FaTrashAlt } from 'react-icons/fa'

class Eliminar extends Component {

   handleModalEliminar = async data => {
      const input = {
         id: data
      }

      this.obtenerImagenes(data)

      await API.graphql(graphqlOperation(deleteInmueble, { input }))

      this.props.handleRecargar()
   }

   obtenerImagenes = async (data) => {
      Storage.list(data+'/')
     .then(result => {
        result.forEach( item => {
           this.obtenerImagen(item)
          })
       })
     .catch(err => console.log(err));
   }
   
   obtenerImagen = async (item) => {
      Storage.remove(item.key)
     .then(result => console.log(result))
     .catch(err => console.log(err));
   }

   render(){
      const data = this.props.data
      return(
         <button className="text-red-500 hover:text-red-600 focus:outline-none m-1" onClick={ () => this.handleModalEliminar(data)}>
            <FaTrashAlt />
         </button>
      );
   }
}

export default Eliminar