import React, { Component } from 'react';
import { FaTrashAlt } from 'react-icons/fa'
import Eliminar from './Eliminar'

class Imagen extends Component {

   handleRecargarEliminado = async (data) => {
      this.props.handleRecargarEliminado(data)
   }

   render(){
      const imagenes = this.props.data
      
      return imagenes.map((imagen) => {
         return(
            <div className='relative shadow border-solid border-2 border-white m-2' key={imagen.eTag}>
               <img className="bg-cover w-56" src={ imagen.url } />
               <div className="absolute top-0 right-0 bg-black bg-opacity-75 text-2xl rounded-md px-2">
                  <Eliminar data={imagen} handleRecargarEliminado={this.handleRecargarEliminado} />
               </div>
            </div>
         )
      })
   }
}

export default Imagen