import React, { Component } from 'react';

class Imagen extends Component {
   render(){
      const url = this.props.url
      const sectionStyle = {
         width: "100%",
         height: "350px",
         // maxWidth: "450px",
         // minWidth: "350px",
         backgroundPosition: "center",
         // height: "50%",
         // backgroundRepeat: "no-repeat",
         backgroundSize: "cover",
         // objectFit: "cover",
         // position: "relative",
         backgroundImage: `url(${url})`
      };

      return(
         <div style={ sectionStyle }></div>
      );
   }
}

export default Imagen