import React from 'react';
import { NavLink } from 'react-router-dom'

const Navbar = () => {
   return(
      <nav>
         <div className="hidden azulFranja text-white md:flex md:flex-wrap md:justify-around lg:justify-center">
            <NavLink className="my-2 menu rounded-full hover:bg-blue-600 lg:mx-2 xl:mx-4" to="/venta">
               <div className="text-center text-2xl md:w-32 lg:w-40 xl:w-48">
                     Venta
               </div>
            </NavLink>
            <NavLink className="my-2 menu rounded-full hover:bg-blue-600 lg:mx-2 xl:mx-4" to="/renta">
               <div className="text-center text-2xl md:w-32 lg:w-40 xl:w-48">
                  Renta
               </div>
            </NavLink>
            <NavLink className="my-2 menu rounded-full hover:bg-blue-600 lg:mx-2 xl:mx-4" to="/terrenos">
               <div className="text-center text-2xl md:w-32 lg:w-40 xl:w-48">
                  Terrenos
               </div>
            </NavLink>
            <NavLink className="my-2 menu rounded-full hover:bg-blue-600 lg:mx-2 xl:mx-4" to="/asesores">
               <div className="text-center text-2xl md:w-32 lg:w-40 xl:w-48">
                  Asesores
               </div>
            </NavLink>
            <NavLink className="my-2 menu rounded-full hover:bg-blue-600 lg:mx-2 xl:mx-4" to="/galeria">
               <div className="text-center text-2xl md:w-32 lg:w-40 xl:w-48">               
                  Clientes
               </div>
            </NavLink>
         </div>
         <div className="md:hidden text-white p-2 azulFranja">
            <div className='flex justify-center my-2'>
               <NavLink className="menu rounded-full hover:bg-blue-600 px-6" to="/venta">
                  <div className="text-center text-2xl w-24">
                     Venta
                  </div>
               </NavLink>
            </div>
            <div className='flex justify-center'>
               <NavLink className="menu rounded-full hover:bg-blue-600 px-6" to="/renta">
                  <div className="text-center text-2xl w-24">
                     Renta
                  </div>
               </NavLink>
               <div className='mx-2'></div>
               <NavLink className="menu rounded-full hover:bg-blue-600 px-6" to="/terrenos">
                  <div className="text-center text-2xl w-24">
                     Terrenos
                  </div>
               </NavLink>
            </div>
            <div className='flex justify-center my-2'>
               <NavLink className="menu rounded-full hover:bg-blue-600 px-6" to="/asesores">
                  <div className="text-center text-2xl w-24">
                     Asesores
                  </div>
               </NavLink>
               <div className='mx-2'></div>
               <NavLink className="menu rounded-full hover:bg-blue-600 px-6" to="/galeria">
                  <div className="text-center text-2xl w-24">
                     Clientes
                  </div>
               </NavLink>
            </div>
         </div>
      </nav>
   )
}

export default Navbar