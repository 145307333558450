import React, { Component } from 'react';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import '../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


class MyEditor extends Component {
  constructor(props) {
    super(props);
    const html = this.props.data;
    const contentBlock = htmlToDraft(html);
    if (contentBlock) {
      const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
      const editorState = EditorState.createWithContent(contentState);
      this.state = {
        editorState,
      };
    }
  }

  onEditorStateChange = async editorState => {
    this.setState({ editorState })
    this.props.handleDescripcion(draftToHtml(convertToRaw(editorState.getCurrentContent())))
  }

  render() {
    const { editorState } = this.state;
    return (
      <div className="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1">
        <Editor
          editorState={editorState}
          wrapperClassName=""
          editorClassName="bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1"
          toolbarClassName=""
          onEditorStateChange={this.onEditorStateChange}
        />
      </div>
    );
  }
}

export default MyEditor