import React from 'react'
import Sidebar from './Sidebar'
import { NavLink } from 'react-router-dom'
import Anio from './Anio'
import { FaFacebookSquare, FaHeartbeat, FaPhoneSquareAlt, FaMapMarked, FaMailBulk } from 'react-icons/fa'
import { FacebookMessengerShareButton, WhatsappShareButton, EmailShareButton, FacebookMessengerIcon, WhatsappIcon, EmailIcon } from "react-share";

const Layout = ({children}) => {

   const shareUrl = document.location.href;
   const title = 'InmobiliariaYes';

   return ( 
      <>
         <div className="bg-white h-auto p-4">
            <Sidebar/>
         </div>
         <div className="bg-gray-200 min-h-screen">
            {children}
         </div>
         <div className="relative azulFranja text-white text-center h-auto">
            <div className="pt-4 lg:flex lg:flex-wrap justify-around">
               <div className="flex justify-center self-center">
                  <div className=''>
                     <div className='flex justify-center'>
                        <img className="h-40" src="/images/logo_transparente.png" alt="Logo" />
                     </div>
                     <a className="flex justify-center text-xl azulBoton rounded-full m-1 px-2"  href="mailto:gabrielsigala@inmobiliariayes.com?Subject=Mas%20Informacion">
                        <FaMailBulk className="self-center mr-2 " />
                        <div>gabrielsigala@inmobiliariayes.com</div>
                     </a>                     
                  </div>
               </div>
               <div className="hidden lg:inline-block">
                  <div className='py-4'>
                     <div className="text-2xl">Para mas información llama al:</div>
                     <div className='flex flex-wrap justify-center pt-4'>
                        <a className="flex justify-center text-2xl azulBoton rounded-full m-1 px-4" target="_blank" href="tel:+523221463728">
                           <FaPhoneSquareAlt className="self-center mr-1" />
                           <div>322-146-3728</div>
                        </a>
                     </div>
                     <div className='flex flex-wrap justify-center pb-2'>
                        <a className="flex justify-center text-2xl azulBoton rounded-full m-1 px-4" target="_blank" href="tel:+523222753755">
                           <FaPhoneSquareAlt className="self-center mr-1" />
                           <div>322-275-3755</div>
                        </a>
                     </div>
                  </div>
                  <div className='hidden lg:inline-block'>
                     <div className="flex justify-center">
                        <div className="text-2xl">Comparte:</div>
                        <div className="self-center flex">
                           <div className='mx-1'>
                              <WhatsappShareButton url={shareUrl} quote={title}>
                                 <WhatsappIcon size={32} round />
                              </WhatsappShareButton>
                           </div>
                           <div className='mx-1'>
                              <FacebookMessengerShareButton url={shareUrl} quote={title} appId="667052410553622">
                                 <FacebookMessengerIcon size={32} round />
                              </FacebookMessengerShareButton>
                           </div>
                           <div className='mx-1'>
                              <EmailShareButton url={shareUrl} quote={title}>
                                 <EmailIcon size={32} round />
                              </EmailShareButton>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="flex justify-center hidden lg:inline-block">
                  <div className=''>
                     <div className="text-2xl py-4">Estamos ubicados en:</div>
                     <a className="" target="_blank" href="https://maps.app.goo.gl/sNPJkBNcnofcnNq29">
                        <div className='flex justify-center text-2xl'>
                           <FaMapMarked className="text-center" />
                        </div>
                        <div className="text-xl">San Vicente 16A</div>
                        <div className="text-xl">63735, Nuevo Vallarta, Nay.</div>
                     </a>
                     <div className="flex justify-center py-4">
                        <div className="self-center text-2xl">Siguenos:</div>
                        <div className="text-5xl mx-1">
                           <a href="https://www.facebook.com/Inmobiliariayes" target="_blank">
                              <FaFacebookSquare />
                           </a>
                        </div>
                     </div>
                  </div>
               </div>
               <div className="md:flex justify-around lg:hidden">
                  <div className="">
                     <div className='py-4'>
                        <div className="text-2xl">Para mas información llama al:</div>
                        <div className='flex flex-wrap justify-center pt-4'>
                           <a className="flex justify-center text-2xl azulBoton rounded-full m-1 px-4" target="_blank" href="tel:+523221463728">
                              <FaPhoneSquareAlt className="self-center mr-1" />
                              <div>322-146-3728</div>
                           </a>
                        </div>
                        <div className='flex flex-wrap justify-center pb-2'>
                           <a className="flex justify-center text-2xl azulBoton rounded-full m-1 px-4" target="_blank" href="tel:+523222753755">
                              <FaPhoneSquareAlt className="self-center mr-1" />
                              <div>322-275-3755</div>
                           </a>
                        </div>
                     </div>
                     <div className=''>
                        <div className="flex justify-center">
                           <div className="text-2xl">Comparte:</div>
                           <div className="self-center flex">
                              <div className='mx-1'>
                                 <WhatsappShareButton url={shareUrl} quote={title}>
                                    <WhatsappIcon size={32} round />
                                 </WhatsappShareButton>
                              </div>
                              <div className='mx-1'>
                                 <FacebookMessengerShareButton url={shareUrl} quote={title} appId="667052410553622">
                                    <FacebookMessengerIcon size={32} round />
                                 </FacebookMessengerShareButton>
                              </div>
                              <div className='mx-1'>
                                 <EmailShareButton url={shareUrl} quote={title}>
                                    <EmailIcon size={32} round />
                                 </EmailShareButton>
                              </div>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="flex justify-center">
                     <div className=''>
                        <div className="text-2xl py-4">Estamos ubicados en:</div>
                        <a className="" target="_blank" href="https://maps.app.goo.gl/sNPJkBNcnofcnNq29">
                           <div className='flex justify-center text-2xl'>
                              <FaMapMarked className="text-center" />
                           </div>
                           <div className="text-xl">San Vicente 16A</div>
                           <div className="text-xl">63735, Nuevo Vallarta, Nay.</div>
                        </a>
                        <div className="flex justify-center py-4">
                           <div className="self-center text-2xl">Siguenos:</div>
                           <div className="text-5xl mx-1">
                              <a href="https://www.facebook.com/Inmobiliariayes" target="_blank">
                                 <FaFacebookSquare />
                              </a>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
            <div className="w-full h-8">
               <div className="flex flex-wrap justify-center p-4 azulFranja">
                  <div>Copyright &copy;</div>
                  <Anio />.
                  <div className="self-center ml-1">Todos los derechos reservados. Hecha con</div>
                  <FaHeartbeat className="self-center mx-2 corazon" /> 
                  <a className="self-center" href="https://abc-ti.com" target="_blank" >Abel Castillo</a>
                  <div className='px-2'>-</div>
                  <div>
                     <NavLink className="" to="/admin">
                        Administración
                     </NavLink>
                  </div>
               </div>
            </div>
         </div>
      </>
    );
}
 
export default Layout;