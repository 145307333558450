/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getAsesor = /* GraphQL */ `
  query GetAsesor($id: ID!) {
    getAsesor(id: $id) {
      id
      nombre
      descripcion
      correo
      telefono
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const listAsesors = /* GraphQL */ `
  query ListAsesors(
    $filter: ModelAsesorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAsesors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        nombre
        descripcion
        correo
        telefono
        urlImagen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCliente = /* GraphQL */ `
  query GetCliente($id: ID!) {
    getCliente(id: $id) {
      id
      ancho
      alto
      urlImagen
      createdAt
      updatedAt
    }
  }
`;
export const listClientes = /* GraphQL */ `
  query ListClientes(
    $filter: ModelClienteFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listClientes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        ancho
        alto
        urlImagen
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInmueble = /* GraphQL */ `
  query GetInmueble($id: ID!) {
    getInmueble(id: $id) {
      id
      folio
      titulo
      precio
      ubicacion
      zona
      tipo
      recamaras
      banios
      estacionamientos
      superficie
      descripcion
      createdAt
      updatedAt
    }
  }
`;
export const listInmuebles = /* GraphQL */ `
  query ListInmuebles(
    $filter: ModelInmuebleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInmuebles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        folio
        titulo
        precio
        ubicacion
        zona
        tipo
        recamaras
        banios
        estacionamientos
        superficie
        descripcion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getInmueblesPorTipoZona = /* GraphQL */ `
  query GetInmueblesPorTipoZona(
    $tipo: TipoInmueble
    $zona: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelInmuebleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getInmueblesPorTipoZona(
      tipo: $tipo
      zona: $zona
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        folio
        titulo
        precio
        ubicacion
        zona
        tipo
        recamaras
        banios
        estacionamientos
        superficie
        descripcion
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
