import React, { Component } from 'react';
import { FaEdit } from 'react-icons/fa'
import FileBase64 from 'react-file-base64';
import { API, graphqlOperation } from 'aws-amplify'
import { updateInmueble } from '../../graphql/mutations';
import Editor from './Editor'

class Modificar extends Component {

   state = {
      verModal : false,
      inmueble : {
         folio: "",
         titulo: "",
         precio: "",
         ubicacion: "",
         zona: "PUERTOVALLARTA",
         tipo: "VENTA",
         recamaras: "",
         banios: "",
         estacionamientos: "",
         superficie: "",
         descripcion: "",
         imagenes: []
      }
   }

   handleModal = (inmueble) => {
      
      this.setState({ inmueble })
      this.setState({ verModal: !this.state.verModal})
   }

   handleFolio = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, folio: event.target.value}
      })
   }
   
   handleTitulo = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, titulo: event.target.value}
      })
   }

   handlePrecio = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, precio: event.target.value}
      })
   }

   handleUbicacion = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, ubicacion: event.target.value}
      })
   }

   handleZona = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, zona: event.target.value}
      })
   }
   
   handleTipo = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, tipo: event.target.value}
      })
   }

   handleRecamaras = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, recamaras: event.target.value}
      })
   }

   handleBanios = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, banios: event.target.value}
      })
   }

   handleEstacionamientos = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, estacionamientos: event.target.value}
      })
   }

   handleSuperficie = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, superficie: event.target.value}
      })
   }

   handleDescripcion = event => {
      this.setState({ 
         inmueble: {...this.state.inmueble, descripcion: event.target.value}
      })
   }

   handleAgregar = async event => {
      event.preventDefault()

      const input = {
         id: this.state.inmueble.id,
         folio: this.state.inmueble.folio,
         titulo: this.state.inmueble.titulo,
         precio: this.state.inmueble.precio,
         ubicacion: this.state.inmueble.ubicacion,
         zona: this.state.inmueble.zona,
         tipo: this.state.inmueble.tipo,
         recamaras: this.state.inmueble.recamaras,
         banios: this.state.inmueble.banios,
         estacionamientos: this.state.inmueble.estacionamientos,
         superficie: this.state.inmueble.superficie,
         descripcion: this.state.inmueble.descripcion
      }

      await API.graphql(graphqlOperation(updateInmueble, { input }))

      this.setState({ inmueble: {...this.state.inmueble, folio: "" } })
      this.setState({ inmueble: {...this.state.inmueble, titulo: "" } })
      this.setState({ inmueble: {...this.state.inmueble, precio: "" } })
      this.setState({ inmueble: {...this.state.inmueble, ubicacion: "" } })
      this.setState({ inmueble: {...this.state.inmueble, zona: "PUERTOVALLARTA" } })
      this.setState({ inmueble: {...this.state.inmueble, tipo: "VENTA" } })
      this.setState({ inmueble: {...this.state.inmueble, recamaras: "" } })
      this.setState({ inmueble: {...this.state.inmueble, banios: "" } })
      this.setState({ inmueble: {...this.state.inmueble, estacionamientos: "" } })
      this.setState({ inmueble: {...this.state.inmueble, superficie: "" } })
      this.setState({ inmueble: {...this.state.inmueble, descripcion: "" } })
      // this.setState({ inmueble: {...this.state.inmueble, imagenes: 0 } })

      this.props.handleRecargar()

      this.setState({ verModal: !this.state.verModal})
   }

   handleDescripcion = value => {
      this.setState({ 
         inmueble: {...this.state.inmueble, descripcion: value}
      })
   } 

   render(){
      const data = this.props.data
      return(
         <>
            <div className='flex justify-center'>
               <button className="text-green-500 hover:text-green-600 focus:outline-none m-1" onClick={() => this.handleModal(data)}>
                  <FaEdit />
               </button>
            </div> 

            { this.state.verModal && (
               <>
                  <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                     <div className="relative w-auto my-6 mx-auto max-w-3xl">
                        {/*content*/}
                        <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                           {/*header*/}
                           <div className="flex items-start justify-between p-5 border-b border-solid border-gray-300 rounded-t">
                              <h3 className="text-3xl font-semibold">Modificar Inmueble</h3>
                              <button className="p-1 ml-auto bg-transparent border-0 text-black opacity-5 float-right text-3xl leading-none font-semibold outline-none focus:outline-none" onClick={this.handleModal}>
                                 <span className="bg-transparent text-black opacity-5 h-6 w-6 text-2xl block outline-none focus:outline-none">×</span>
                              </button>
                           </div>
                           {/*body*/}
                           <div className="relative p-6 flex-auto">
                              <div className="my-4 text-gray-600 text-lg leading-relaxed">
                                 <div className="overflow-auto max-h-70">
                                 <div className='flex'>
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="texto" 
                                          placeholder="Folio" 
                                          name="inmuebleFolio" 
                                          value={this.state.inmueble.folio}
                                          onChange={this.handleFolio} />
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="texto" 
                                          placeholder="Titulo" 
                                          name="inmuebleTitulo" 
                                          value={this.state.inmueble.titulo}
                                          onChange={this.handleTitulo} />
                                    </div>
                                    <div className='flex'>                                       
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="number" 
                                          placeholder="Precio" 
                                          name="inmueblePrecio" 
                                          value={this.state.inmueble.precio}
                                          onChange={this.handlePrecio} />                                   
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="texto" 
                                          placeholder="Ubicacion" 
                                          name="inmueble Ubicacion" 
                                          value={this.state.inmueble.ubicacion}
                                          onChange={this.handleUbicacion} />                                 
                                    </div>
                                    <div className='flex'>
                                       <select className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          value={this.state.inmueble.zona} 
                                          onChange={this.handleZona}>
                                          <option value="PUERTOVALLARTA">PUERTO VALLARTA</option>
                                          <option value="NUEVOVALLARTA">NUEVO VALLARTA</option>
                                       </select>
                                       <select className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          value={this.state.inmueble.tipo} 
                                          onChange={this.handleTipo}>
                                          <option value="VENTA">VENTA</option>
                                          <option value="RENTA">RENTA</option>
                                          <option value="TERRENO">TERRENO</option>
                                       </select>
                                    </div>
                                    <div className='flex'>
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="number" 
                                          placeholder="Recamaras" 
                                          name="inmuebleRecamaras" 
                                          value={this.state.inmueble.recamaras}
                                          onChange={this.handleRecamaras} />                                   
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="number" 
                                          placeholder="Baños" 
                                          name="inmuebleBanios" 
                                          value={this.state.inmueble.banios}
                                          onChange={this.handleBanios} />                                   
                                    </div>
                                    <div className='flex'>
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="number" 
                                          placeholder="Estacionamientos" 
                                          name="inmuebleEstacionamientos" 
                                          value={this.state.inmueble.estacionamientos}
                                          onChange={this.handleEstacionamientos} />                                   
                                       <input className='bg-white focus:outline-none focus:shadow-outline border border-gray-300 rounded-lg py-2 px-4 block w-full appearance-none leading-normal my-1'
                                          type="number" 
                                          placeholder="Superficie" 
                                          name="inmuebleSuperficie" 
                                          value={this.state.inmueble.superficie}
                                          onChange={this.handleSuperficie} />
                                    </div>
                                    <Editor data="" handleDescripcion={this.handleDescripcion}/>
                                 </div>
                              </div>
                           </div>
                           {/*footer*/}
                           <div className="flex items-center justify-end p-6 border-t border-solid border-gray-300 rounded-b">
                              <button className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleModal}>Cancelar</button>
                              <button className="bg-green-500 text-white active:bg-green-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1" type="button" style={{ transition: "all .15s ease" }} onClick={this.handleAgregar}>Guardar</button>
                           </div>
                        </div>
                     </div>
                  </div>
                  <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
               </>
            )}
         </>
      );
   }
}

export default Modificar