import React, { Component } from 'react';
import Layout from './Layout'

class Principal extends Component {
   render(){
      return(
         <Layout>
            <div className="">
               <div className='text-center'>
                  <div className='mt-20 text-6xl'>
                     Bienvenido al Modulo de Administración
                  </div>
                  <div className='text-xl'>
                     Versión Actual: 1.0.2
                  </div>
               </div>
               <div className='flex justify-center mt-8'>
                  <div className='border border-2 border-gray-600 w-full mx-3 p-2'>
                     <div className='text-xl'>Versión: 1.0.2</div>
                     <ul class='ml-4 list-disc list-inside'>
                        <li>
                           Modulo: Clientes
                           <ul class='ml-6 list-decimal list-inside'>
                              <li>Se ajustan las tarjetas para que todas tengan el mismo tamaño.</li>
                           </ul>
                        </li>
                     </ul>
                     <div className='text-xl'>Versión: 1.0.1</div>
                     <ul class='ml-4 list-disc list-inside'>
                        <li>
                           Modulo: Administración
                           <ul class='ml-6 list-decimal list-inside'>
                              <li>Se corrige error en botón "ver mas" en la sección de inmuebles, ya que solo permitía ver un máximo de 10 inmuebles.</li>
                           </ul>
                        </li>
                        <li>
                           Modulo: Clientes
                           <ul class='ml-6 list-decimal list-inside'>
                              <li>Se corrige tamaño de imágenes en la pantalla principal de clientes.</li>
                              <li>Se agrega filtro para ordenar por menor precio.</li>
                              <li>Se ajustan los tamaños de la pantalla de cliente para que todas las tarjetas tengan el mismo tamaño.</li>
                           </ul>
                        </li>
                     </ul>
                  </div>
               </div>
            </div>
         </Layout>
      );
   }
}

export default Principal