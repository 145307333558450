import React, { Component } from 'react';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import { listClientes } from '../../graphql/queries';
import ClientesTabla from './ClientesTabla';
import Cliente from './Cliente';
import Layout from '../Layout'

class Clientes extends Component {

   state = {
      clientes: [],
      nextToken: "",
      next: false,
   }

   componentDidMount = async () => {
      this.getClientes()
   }

   async asyncForEach(array, callback) {
      for (let index = 0; index < array.length; index++) {
         await callback(array[index], index, array);
      }
   }

   getClientes = async () => {
      this.setState({clientes : []})
      const result = await API.graphql(graphqlOperation(listClientes))
      await this.asyncForEach(result.data.listClientes.items, async (item) => {
         await Storage.get(item.urlImagen)
         .then(result => {
            const cliente = {
               id: item.id,
               ancho: item.ancho,
               alto: item.alto,
               urlImagen: item.urlImagen,
               url: result
            }
            this.setState({ clientes: [...this.state.clientes, cliente] })
         })
         .catch(err => {
            this.setState({ asesores: [...this.state.asesores, item] })
         });         
      });
      this.setState({ nextToken: result.data.listClientes.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   nextClientes = async () => {
      const nextToken = this.state.nextToken

      const result = await API.graphql(graphqlOperation(listClientes, { nextToken } ))
      await this.asyncForEach(result.data.listClientes.items, async (item) => {
         await Storage.get(item.urlImagen)
         .then(result => {
            const cliente = {
               id: item.id,
               ancho: item.ancho,
               alto: item.alto,
               urlImagen: item.urlImagen,
               url: result
            }
            this.setState({ clientes: [...this.state.clientes, cliente] })
         })
         .catch(err => {
            this.setState({ asesores: [...this.state.asesores, item] })
         });         
      });
      this.setState({ nextToken: result.data.listClientes.nextToken})
      if(this.state.nextToken === null){
         this.setState({ next: false})         
      } else {
         this.setState({ next: true})                
      }
   }

   handleRecargar = async () => {
      this.getClientes()
   }

   render(){
      const { clientes } = this.state

      return(
         <Layout>
            <div className='shadow m-2'>            
               <Cliente handleRecargar={this.handleRecargar} />
               <div className='flex justify-center text-xl'>
                  <table className='table-auto w-full'>
                     <thead>
                        <tr className="bg-indigo-600 text-white">
                           <th className="border px-4 py-2">Imagen</th>
                           <th className="border px-4 py-2 w-56">Ancho</th>
                           <th className="border px-4 py-2 w-56">Alto</th>
                           <th className="border px-4 py-2 w-64">Acciones</th>
                        </tr>
                     </thead>
                     <tbody className="">
                        <ClientesTabla clientes={clientes} handleRecargar={this.handleRecargar} />
                     </tbody>
                  </table>
               </div>
               { this.state.next && (
                  <div className=''>
                     <button className="border-solid border-2 bg-indigo-700 text-white p-4 text-xl w-full" onClick={this.nextClientes}>Mostrar más</button>
                  </div>
               ) }
            </div>
         </Layout>
      );
   }
}

export default Clientes