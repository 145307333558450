import React, { Component } from 'react';
import { deleteCliente } from '../../graphql/mutations';
import { API, graphqlOperation, Storage } from 'aws-amplify'
import {  FaTrashAlt } from 'react-icons/fa'

class Eliminar extends Component {

   handleModalEliminar = async (cliente) => {

      Storage.remove(cliente.urlImagen)
      .then(async result => {
         const input = {
            id: cliente.id
         }      
         await API.graphql(graphqlOperation(deleteCliente, { input }))
         this.props.handleRecargar()
      })
      .catch(err => console.log(err));
   }

   render(){
      const data = this.props.data
      return(
         <button className="text-red-500 hover:text-red-600 focus:outline-none m-1" onClick={ () => this.handleModalEliminar(data)}>
            <FaTrashAlt />
         </button>
      );
   }
}

export default Eliminar